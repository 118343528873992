@font-face {
  font-family: "SF Pro Text";
  src: url("./SFProText-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("./SFProText-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("./SFProText-Semibold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("./SFProText-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("./SFProText-Heavy.ttf");
  font-weight: 800;
}
